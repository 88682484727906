<template>
  <div class="evaluate-page" v-loading="loading">
    <!-- <div class="item">
      <p class="title">默认类型</p>
      <el-divider></el-divider>
      <el-checkbox-group v-model="defaultCheckList">
        <el-checkbox
          :label="item.id"
          v-for="(item, index) in defaultType"
          :key="index"
          :disabled="disabledList.includes(item.id)"
        >
          {{ item.reviewTypeTitle }}
        </el-checkbox>
      </el-checkbox-group>
    </div> -->
    <div class="item">
      <p class="title">自定义类型</p>
      <el-divider></el-divider>
      <div>
        <el-tag v-for="(item, index) in customType" :key="index" class="cs_item">
          {{ item.reviewTypeTitle }}
          <i class="el-icon-edit edit-btn" @click="handleEdit(item)"></i>
        </el-tag>
      </div>
      <el-button type="primary" class="btn-add" slot="reference" @click="handleAdd">新增</el-button>
    </div>
    <el-dialog :close-on-click-modal="false" title="类型名称" :visible.sync="showPop" width="30%">
      <el-input v-model="inputValue" placeholder="请输入类型名称" clearable></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showPop = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      defaultType: [],
      customType: [],
      defaultCheckList: [0, 1],
      customCheckList: [],
      disabledList: [1],
      inputValue: '',
      showPop: false,
      loading: false,
      isEdit: false,
      curId: 0
    }
  },
  computed: {},
  watch: {
    showPop(nV) {
      if (!nV) {
        this.inputValue = ''
      }
    }
  },
  methods: {
    handleAdd() {
      this.showPop = true
    },
    loadData() {
      this.loading = true
      this.$api.product
        .product('selectList')
        .then(res => {
          this.customType = res
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleData(type, data) {
      let url = type === 'add' ? 'addReviewType' : 'updateReviewType'
      this.loading = true
      this.$api.product
        .product(url, data)
        .then(res => {
          this.$message.success('操作成功')
          this.loadData()
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleEdit(item) {
      this.isEdit = true
      this.curId = item.id
      this.showPop = true
      this.inputValue = item.reviewTypeTitle
    },
    handleConfirm() {
      if (!this.inputValue) {
        this.showPop = true
        this.$message.error('类型名称不能为空!')
        return
      }
      let valueCol = this.customType.map(item => item.name)
      if (valueCol.includes(this.inputValue)) {
        this.showPop = true
        this.$message.error('类型名称不能重复!')
        return
      }
      this.showPop = false
      let type = this.isEdit === true ? 'edit' : 'add'
      if (type === 'add') {
        this.handleData('add', {
          reviewTypeTitle: this.inputValue
        })
      } else {
        this.handleData('edit', {
          id: this.curId,
          reviewTypeTitle: this.inputValue
        })
        this.curId = 0
        this.isEdit = false
      }
    }
  },
  created() {
    this.loadData()
  }
}
</script>

<style lang="less" scoped>
.evaluate-page {
  .item {
    margin-bottom: 24px;
  }
  .btn-add {
    margin-top: 10px;
  }
  .cs_item {
    margin-right: 10px;
    .edit-btn {
      cursor: pointer;
    }
  }
}
</style>
