<route>
{
  "meta": {
    "auth": "vendorCategoryList"
  }
}
</route>

<template>
  <div>
    <el-card>
      <el-tabs v-model="activeName" @tab-click="handleTabClick">
        <el-tab-pane label="分类列表" name="classify">
          <el-card class="searchContainer">
            <div class="desc">筛选查询</div>
            <el-row :gutter="20" class="query">
              <el-col :span="8">
                <span class="searchText">分类名称</span>
                <el-input v-model.trim="searchValue" class="searchInput" placeholder="分类名称" clearable> </el-input>
              </el-col>
              <el-col :span="12">
                <el-button type="info" @click="resetSearch()">重置</el-button>
                <el-button type="primary" @click="getList()"> 搜索</el-button></el-col
              >
            </el-row>
          </el-card>
          <el-card class="tableContainer">
            <div slot="header" class="clearfix">
              <span>分类列表</span>
              <el-button v-auth="'vendorCategoryAdd'" type="success" @click="handleAddClassify" style="float: right; "
                >添加分类</el-button
              >
            </div>

            <el-table
              :data="firstLevel"
              style="width: 100%;margin-bottom: 20px;"
              row-key="id"
              default-expand-all
              header-cell-class-name="tableHeader"
              :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            >
              <el-table-column label="一级" align="center">
                <template slot-scope="scope">
                  <div @click="firstClick(scope)">
                    <i v-if="!scope.row.isOpened && scope.row.one" class="el-icon-arrow-down"></i>
                    <i v-if="scope.row.isOpened && scope.row.one" class="el-icon-arrow-up"></i>
                    <span> {{ scope.row.one }} </span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="一级分类评价类型" align="center">
                <template slot-scope="{ row }">
                  <p>{{ (row.reviewTypeTextList && row.reviewTypeTextList.join()) || '--' }}</p>
                </template>
              </el-table-column>
              <el-table-column label="二级" align="center">
                <template slot-scope="scope">
                  {{ scope.row.second }}
                </template>
              </el-table-column>
              <el-table-column prop="businessCount" label="关联商家" align="center"> </el-table-column>
              <el-table-column prop="sortNumber" label="排序" align="center"> </el-table-column>
              <el-table-column label="商家上架" align="center">
                <template slot-scope="{ row }">
                  {{ row.requestSources | calcText }}
                </template>
              </el-table-column>
              <el-table-column prop="address" label="操作" align="center">
                <template slot-scope="scope">
                  <el-button v-auth="'vendorCategoryEdit'" type="text" size="small" @click="edit(scope)"
                    >编辑</el-button
                  >
                  <el-button
                    v-auth="'vendorCategoryDelete'"
                    type="text"
                    @click="showDelete(scope)"
                    size="small"
                    v-if="scope.row.businessCount === 0 || scope.row.businessCount === '0'"
                    class="deleteBtnText"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </el-tab-pane>
        <el-tab-pane label="评价类型" name="evaluate">
          <Evaluate />
        </el-tab-pane>
      </el-tabs>
    </el-card>

    <!-- 删除分类的组件 -->
    <bm-deleteItem
      url="boom-center-product-service/sysAdmin/businessCategory/"
      :timestamp="timestamp"
      @successDelete="successDelete"
    ></bm-deleteItem>

    <!-- 添加分类的弹框 -->

    <el-dialog :close-on-click-modal="false" title="添加分类" :visible.sync="addClassifyDialog" width="40%">
      <el-row :gutter="20" type="flex" justify="space-between">
        <el-col :span="12">
          <div class="el-transfer-panel">
            <p class="el-transfer-panel__header">
              <span class="el-checkbox__input "> <span class="el-checkbox__label"> 一级分类 </span></span>
            </p>
            <div class="classifyBody">
              <div class="classifyItems">
                <div
                  v-for="(item, index) in firstLevelDialog"
                  :key="index"
                  class="classifyItem"
                  :class="{ activeClass: itemFirstId === item.id }"
                  @click="selectFirst(item.id)"
                >
                  {{ item.businessCategoryTitle }}
                </div>
              </div>

              <div>
                <el-input v-if="addFirst === true" class="className" v-model.trim="addClass.firstName"></el-input>
                <el-button v-if="addFirst === true" type="text" @click="addClassName">确定</el-button>

                <el-button v-else type="text" class="addClass" @click="addFirst = true">增加一级分类</el-button>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="12"
          ><div>
            <div class="el-transfer-panel">
              <p class="el-transfer-panel__header">
                <span class="el-checkbox__input "> <span class="el-checkbox__label"> 二级分类 </span></span>
              </p>
              <div class="classifyBody">
                <div class="classifyItems">
                  <div
                    v-for="(item, index) in secondClass"
                    :key="index"
                    class="classifyItem"
                    :class="{ activeClass: itemSecondId === item.id }"
                    @click="selectSecond(item.id)"
                  >
                    {{ item.businessCategoryTitle }}
                  </div>
                </div>

                <div>
                  <el-input v-if="addSecond === true" class="className" v-model.trim="addClass.secondName"></el-input>
                  <el-button v-if="addSecond === true" type="text" @click="addSecondClassName">确定</el-button>

                  <el-button v-else type="text" class="addClass" @click="addSecond = true">增加二级分类</el-button>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button round @click="addClassifyDialog = false">关闭添加分类</el-button>
      </span>
    </el-dialog>
    <!-- 编辑一级分类 -->
    <el-dialog
      :close-on-click-modal="false"
      title="编辑一级分类"
      :visible.sync="firstDialog"
      width="25%"
      class="classifyDialog"
    >
      <el-form ref="firstForm" :model="firstForm" :rules="firstRules" label-width="80px">
        <el-form-item label="商家上架" prop="requestSources">
          <el-checkbox-group v-model="firstForm.requestSources">
            <el-checkbox :label="1">微信小程序</el-checkbox>
            <el-checkbox :label="2">H5</el-checkbox>
            <!-- <el-checkbox :label="3">后台</el-checkbox> -->
            <!-- <el-checkbox :label="4">抖音小程序</el-checkbox> -->
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="分类名称" prop="businessCategoryTitle">
          <el-input v-model.trim="firstForm.businessCategoryTitle"></el-input>
        </el-form-item>
        <el-form-item label="分类排序" prop="sortNumber">
          <el-input-number
            v-model="firstForm.sortNumber"
            controls-position="right"
            :min="0"
            :step="1"
            :max="9999"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="是否显示">
          <el-switch v-model="firstForm.hideStatus" :active-value="0" :inactive-value="1"></el-switch>
        </el-form-item>
        <el-form-item label="评价类型" prop="reviewTypeIdList">
          <span>最多勾选五个</span>
          <el-checkbox-group v-model="firstForm.reviewTypeIdList">
            <el-checkbox
              :label="item.id"
              v-for="(item, index) in checkType"
              :key="index"
              :disabled="firstForm.reviewTypeIdList.length === 5 && !firstForm.reviewTypeIdList.includes(item.id)"
            >
              {{ item.reviewTypeTitle }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button round @click="firstDialog = false">取消</el-button>
        <el-button round type="primary" @click="editProductCategory(0)">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑二级，三级分类 -->

    <el-dialog
      :close-on-click-modal="false"
      title="编辑二级分类"
      :visible.sync="secondDialog"
      width="25%"
      class="classifyDialog"
    >
      <el-form ref="secondOrThirdForm" :rules="secondRules" :model="secondOrThirdForm" label-width="80px">
        <el-form-item label="商家上架" prop="requestSources">
          <el-checkbox-group v-model="secondOrThirdForm.requestSources">
            <el-checkbox :label="1" :disabled="!firstForm.requestSources.includes(1)">微信小程序</el-checkbox>
            <el-checkbox :label="2" :disabled="!firstForm.requestSources.includes(2)">H5</el-checkbox>
            <!-- <el-checkbox :label="3">后台</el-checkbox> -->
            <!-- <el-checkbox :label="4" :disabled="!firstForm.requestSources.includes(4)">抖音小程序</el-checkbox> -->
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="所属分类" prop="parentId">
          <el-select v-model="secondOrThirdForm.parentId" placeholder="请选择所属分类" disabled>
            <el-option
              v-for="(item, index) in firstDialogChange"
              :label="item.businessCategoryTitle"
              :value="item.id"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分类名称" prop="businessCategoryTitle">
          <el-input v-model.trim="secondOrThirdForm.businessCategoryTitle"></el-input>
        </el-form-item>
        <el-form-item label="分类排序" prop="sortNumber">
          <el-input-number
            v-model="secondOrThirdForm.sortNumber"
            controls-position="right"
            :min="0"
            :step="1"
            :max="9999"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="是否显示">
          <el-switch v-model="secondOrThirdForm.hideStatus" :active-value="0" :inactive-value="1"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button round @click="secondDialog = false">取 消</el-button>
        <el-button type="primary" round @click="editProductCategory(1)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { deleteRowItem } from '@/mixin/deleteRowItem'
import Evaluate from './evaluate'

export default {
  mixins: [deleteRowItem],
  components: {
    Evaluate
  },
  data() {
    return {
      //删除分类的
      timestamp: 0,
      // 添加分类的名称
      addClass: { firstName: '', secondName: '' },
      // 添加分类弹框真假值决定是否显示输入框和确定按钮
      addFirst: false,
      addSecond: false,
      // 添加分类数据展示列表
      secondClass: [],
      firstLevel: [],
      // 弹框点击一级或二级分类的ID
      itemFirstId: '',
      itemSecondId: '',

      firstDialogChange: [],
      firstLevelDialog: [],
      searchValue: '',
      // 是否显示弹框
      addClassifyDialog: false,
      firstDialog: false,
      secondDialog: false,
      // 编辑一级分类的表单
      firstForm: {
        requestSources: [3],
        parentId: 0,
        sortNumber: 0,
        hideStatus: 1,
        businessCategoryTitle: '',
        reviewTypeIdList: []
      },
      // 编辑二级或者三级分类的表单
      secondOrThirdForm: {
        requestSources: [3],
        parentId: 0,
        sortNumber: 0,
        hideStatus: 1,
        businessCategoryTitle: ''
      },
      searchValue: '',
      tempFirst: [],
      activeName: 'classify',
      checkType: [],
      defaultCheckList: [0, 1],
      defaultRequestSources: [1, 2, 3],
      firstRules: {
        requestSources: [
          // 写2: 默认必须传后台(3)给接口
          {
            required: true,
            message: '请选择商家上架',
            trigger: 'change',
            type: 'array',
            min: 2
          }
        ],
        businessCategoryTitle: [
          {
            required: true,
            message: '请输入商家名称',
            trigger: 'blur'
          }
        ],
        sortNumber: [
          {
            required: true,
            message: '请输入商家排序',
            trigger: 'blur'
          }
        ],
        reviewTypeIdList: [
          {
            required: true,
            message: '请勾选评价类型',
            trigger: 'change'
          }
        ]
      },
      secondRules: {
        requestSources: [
          // 写2: 默认必须传后台(3)给接口
          {
            required: true,
            message: '请选择商家上架',
            trigger: 'change',
            type: 'array',
            min: 2
          }
        ],
        businessCategoryTitle: [
          {
            required: true,
            message: '请输入商家名称',
            trigger: 'blur'
          }
        ],
        sortNumber: [
          {
            required: true,
            message: '请输入商家排序',
            trigger: 'blur'
          }
        ]
      },
      secResouces: []
    }
  },
  created() {
    var that = this
    document.onkeydown = function(e) {
      var key = window.event.keyCode
      if (key == 13) {
        that.getList()
      }
    }
    this.getRules()
    this.productCategoryDialog()
    this.loadSelectData().then(_ => {
      // 获取表格列表的一级分类 && 获取 添加分类的一级分类
      this.getList()
    })
  },
  filters: {
    calcText(val) {
      if (val.length === 3) {
        //pc,微信小程序,h5
        return '多端'
      } else {
        let filterVal = val.filter(item => item !== 3) //过滤后台，不进行展示
        return filterVal
          .map(item => {
            if (item === 1) {
              return '微信小程序'
            } else if (item === 2) {
              return 'H5'
            }
          })
          .join()
      }
    }
  },
  methods: {
    loadSelectData() {
      this.loading = true
      return this.$api.product
        .product('selectList')
        .then(res => {
          this.checkType = res
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleTabClick() {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          type: this.activeName
        }
      })
    },
    getRules() {
      // this.firstRules = this.$initRules([
      //   {
      //     label: '商家名称',
      //     value: 'businessCategoryTitle',
      //     type: 'input',
      //     required: true
      //   },
      //   {
      //     label: '商家排序',
      //     value: 'sortNumber',
      //     type: 'input',
      //     required: true
      //   },
      //   {
      //     label: '评价类型',
      //     value: 'checkTypeProp',
      //     type: 'select',
      //     required: true
      //   }
      // ])
      // this.secondRules = this.$initRules([
      //   {
      //     label: '所属分类',
      //     value: 'parentId',
      //     type: ' select',
      //     required: true
      //   },
      //   {
      //     label: '商家名称',
      //     value: 'businessCategoryTitle',
      //     type: 'input',
      //     required: true
      //   },
      //   {
      //     label: '商家排序',
      //     value: 'sortNumber',
      //     type: 'input',
      //     required: true
      //   }
      // ])
    },

    // =============处理表格的方法============

    // 点击一级分类
    firstClick(scope) {
      if (!this.firstLevel[scope.$index].isOpened) {
        let arr = []
        let tempFirstArr = this.firstLevel
        this.tempFirst.forEach(item => {
          if (item.id === scope.row.id) {
            item.child.forEach(childItem => {
              console
              childItem.level = 2
              childItem.second = childItem.businessCategoryTitle
              childItem.third = childItem.childThirdLevelCount
              childItem.parentId = scope.row.id
              childItem.isOpened = false
              arr.push(childItem)
            })
            tempFirstArr.splice(scope.$index + 1, 0, ...arr)
            this.$set(this, 'firstLevel', tempFirstArr)
          }
        })

        this.firstLevel[scope.$index].isOpened = true
      } else {
        // 如果是关闭的话，我就删除

        this.firstLevel.splice(scope.$index + 1, scope.row.second)
        this.firstLevel[scope.$index].isOpened = false
        console.log(this.firstLevel[scope.$index].isOpened)
      }
    },
    resetSearch() {
      this.searchValue = ''
      this.getList()
    },
    // 获取表格的一级分类
    getList() {
      this.$api.vendor
        .vendor('businessCategoryFirst', {
          params: {
            search: this.searchValue
          }
        })
        .then(res => {
          let { list } = res
          list.forEach(item => {
            item.level = 1
            item.one = item.businessCategoryTitle
            item.isOpened = false
            item.second = parseInt(item.child.length) //转成数字，在表格内有用
            item.third = item.childThirdLevelCount
            item.reviewTypeTextList = item.reviewTypeIdList.map(i => {
              let cur = this.checkType.find(c => +c.id === +i)
              return cur && cur.reviewTypeTitle
            })
          })
          this.firstLevel = list
          this.tempFirst = list
          this.firstDialogChange = list
        })
        .catch(err => {
          this.$message.error('获取一级表格失败')
        })
    },

    // =====================修改分类的方法=====================

    // 点击编辑按钮，根据值显示不同的编辑弹框
    edit(formVal) {
      if (formVal.row.level === 1) {
        this.firstDialog = true
        let curItem = this.firstLevel.find(item => item.id === formVal.row.id)
        let curSet = new Set()
        curItem.child.forEach(item => {
          item.requestSources.forEach(i => {
            curSet.add(i)
          })
        })
        let result = Array.from(curSet)
        console.log(result)
        this.firstForm = JSON.parse(JSON.stringify(formVal.row))
        this.secResouces = result
      } else if (formVal.row.level === 2) {
        this.secondDialog = true
        let pItem = this.firstLevel.find(item => item.id === formVal.row.parentId)
        this.firstForm = JSON.parse(JSON.stringify(pItem))
        this.secondOrThirdForm = JSON.parse(JSON.stringify(formVal.row))
      }
    },

    // 修改分类
    editProductCategory(val) {
      // 根据传入的id，判断是修改几级分类
      if (val === 0) {
        let bool = true
        if (this.secResouces.length > this.firstForm.requestSources.length) {
          // 子的上架平台大于一级平台
          bool = false
        } else {
          // 二级的上架平台 不存在与一级平台
          let hasNoExit = this.secResouces.some(item => !this.firstForm.requestSources.includes(item))
          console.log(hasNoExit)
          bool = !hasNoExit
        }
        console.log(bool, this.secResouces, this.firstForm.requestSources)
        if (!bool) {
          this.$message.error('请先修改二级商家分类')
          return
        }
        this.$refs['firstForm'].validate((valid, validObj) => {
          if (!valid) {
            this.$message.error(validObj[Object.keys(validObj)[0]][0].message)
            return true
          } else {
            this.$api.vendor
              .vendor('putBusinessCategory', {
                requestSources: this.firstForm.requestSources,
                businessCategoryTitle: this.firstForm.businessCategoryTitle,
                hideStatus: this.firstForm.hideStatus,
                id: this.firstForm.id.toString(),
                parentId: 0,
                sortNumber: parseInt(this.firstForm.sortNumber),
                reviewTypeIdList: this.firstForm.reviewTypeIdList
              })
              .then(res => {
                this.$message.success('恭喜你，' + this.firstForm.businessCategoryTitle + '修改成功')
                this.firstDialog = false
                this.getList()
              })
              .catch(err => {
                this.$message.error(err.msg || '操作失败')
              })
          }
        })
      } else {
        this.$refs['secondOrThirdForm'].validate((valid, validObj) => {
          if (!valid) {
            this.$message.error(validObj[Object.keys(validObj)[0]][0].message)
            return true
          } else {
            this.$api.vendor
              .vendor('putBusinessCategory', {
                requestSources: this.secondOrThirdForm.requestSources,
                businessCategoryTitle: this.secondOrThirdForm.businessCategoryTitle,
                hideStatus: this.secondOrThirdForm.hideStatus,
                id: this.secondOrThirdForm.id,
                parentId: this.secondOrThirdForm.parentId,
                sortNumber: this.secondOrThirdForm.sortNumber
              })
              .then(res => {
                this.$message.success('恭喜你，' + this.secondOrThirdForm.businessCategoryTitle + '修改成功')
                this.secondDialog = false
                this.getList()
              })
              .catch(err => {
                this.$message.error(err.msg || '操作失败')
              })
          }
        })
      }
    },
    // =====================弹框方法=======================
    handleAddClassify() {
      this.productCategoryDialog()
      this.addClassifyDialog = true
    },
    // 弹框添加一级分类
    addClassName() {
      if (!this.addClass.firstName) {
        this.$message.error('商家分类名不能为空')
        return
      }

      this.$api.vendor
        .vendor('postBusinessCategory', {
          requestSources: this.defaultRequestSources, //默认小程序，h5, pc
          businessCategoryTitle: this.addClass.firstName,
          hideStatus: 0,
          parentId: 0,
          sortNumber: 0,
          reviewTypeIdList: []
        })
        .then(res => {
          this.addFirst = false //  取消输入框和确定
          this.addClass.firstName = '' // 清楚输入框的值
          this.productCategoryDialog()
          this.getList()
        })
        .catch(err => {
          this.$message.error(err.msg || '添加失败')
        })
    },

    // 弹框添加二级分类
    addSecondClassName() {
      let curItem = this.firstLevel.find(item => item.id === this.itemFirstId)
      if (!this.addClass.secondName) {
        this.$message.error('商家分类名不能为空')
        return
      }
      this.$api.vendor
        .vendor('postBusinessCategory', {
          requestSources: curItem.requestSources, //取决于一级分类
          businessCategoryTitle: this.addClass.secondName,
          hideStatus: 0,
          parentId: this.itemFirstId,
          sortNumber: 0
        })
        .then(res => {
          this.addSecond = false
          this.addClass.secondName = ''
          this.getList()
          this.selectFirst(this.itemFirstId)
        })
        .catch(err => {
          this.$message.error(err.msg || '添加二级分类失败')
        })
    },
    selectSecond(id) {
      this.itemSecondId = id
    },

    // 向后端发起请求获取弹框一级分类
    productCategoryDialog() {
      this.$api.vendor
        .vendor('businessCategoryFirst')
        .then(res => {
          let { list } = res
          this.firstLevelDialog = list
          if (list && list.length) {
            this.selectFirst(list[0].id)
          }
        })
        .catch(err => {
          this.$message.error(err.msg || '获取一级分类失败')
          // const { list } =
          // let { list } = res
          // this.firstLevelDialog = list
          // if (list.length) {
          //   this.selectFirst(list[0].id)
          //   // 如果不是空列表，就选择选择第一个值，然后请求二级分类
          // }
        })
      // .catch(err => {
      //   this.$message.error(err.msg)
      // })
    },
    // 向后端发起请求获取弹框二级分类
    selectFirst(id) {
      this.$api.vendor
        .vendor('businessCategoryAllId', id)
        .then(res => {
          this.itemFirstId = id
          this.secondClass = res.length ? res : []
        })
        .catch(err => {
          this.$message.error('获取二级分类失败')
        })
    }
  },
  mounted() {
    let { type } = this.$route.query
    if (type) {
      this.activeName = type
    }
  }
}
</script>

<style lang="less" scoped>
.classifyBody {
  text-align: center;
  height: 300px;
  .classifyItems {
    line-height: 30px;
    cursor: grab;
    height: 270px;
    overflow-y: scroll;
    width: calc(100% + 18px);
  }
  .className {
    width: 80%;
    margin: 0 5px;
  }
  .activeClass {
    color: @color-primary-select;
    background: #f5f5f5;
  }
  .addClass {
    position: absolute;
    bottom: 0;
    left: calc(50% - 40px);
    width: 80px;
  }
}

.el-transfer-panel .el-transfer-panel__header {
  margin: 0;
  text-align: center;
  padding-left: 0;
}

.classifyDialog {
  .el-select,
  .el-input-number,
  .el-input {
    width: 300px;
  }
}

// 空白各自的颜色 dfdfdf
</style>
